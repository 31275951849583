// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo, useState } from "react";

// project modules
import Input from "../../shared/inputs/input";
import Loader from "../../shared/loader";
import yup from "../../../plugins/yup";
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import Popup from '../../shared/popup/popup';
import { Account } from '../../../models/account';
import { AccountBlockedSite } from '../../../models/accountBlockedSite';
import { ApiResponse } from "../../../models/response";
import { accountBlockedSiteViewModel } from '../../../models/types/accountBlockedSite';
import { convertToSnakecase } from '../../../helpers/objectHelper';

type Props = {
  closeOnSave?: boolean;
  open: boolean;
  account: Account;
  accountBlockedSite: AccountBlockedSite;
  kiboshDeviceReference: string;
  deviceReference: string;
  onClose?: () => void;
  onSave?: (accountBlockedSite: any) => void;
};

export default function AccountBlockedSiteModal({ closeOnSave = false, open, account, accountBlockedSite, kiboshDeviceReference, deviceReference, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    match: yup.string().label("Blocked Site Domain").required()
    .test("no-http", "The domain entry will not accept HTTPS://, WWW or any characters after the domain.com (or .net, .org, etc).", function (val) {
      const valLow = val.toLocaleLowerCase();
      if (valLow.includes("http://") || valLow.includes("https://")) {
        return false;
      }

      return true;
    })
    .test("no-sub-route", "The domain entry will not accept HTTPS://, WWW or any characters after the domain.com (or .net, .org, etc).", function (val) {
      if (val.includes("/")) {
        return false;
      }

      return true;
    })
    // .test("no-sub-domain", "The domain entry will not accept HTTPS://, WWW or any characters after the domain.com (or .net, .org, etc).", function (val) {
    //   const subs = val.split(".")
    //   if (subs.length > 2) {
    //     return false;
    //   }

    //   return true;
    // })
    .test("no-www-domain", "The domain entry will not accept HTTPS://, WWW or any characters after the domain.com (or .net, .org, etc).", function (val) {
      return !val.toLocaleLowerCase().includes("www.");
    })
    .test("no-valid-url", "Please use a valid domain", function (val) {
      // Regular expression to match domains without http or https and without internal paths
      const domainPattern: RegExp = /^(?:[a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:$|\/$|\/[^/]*)?$/;

      // Check if the domain matches the pattern
      return domainPattern.test(val);
    }),
  });
  const { control, handleSubmit } = useForm<accountBlockedSiteViewModel | any>({
    defaultValues: useMemo(() => accountBlockedSite, [accountBlockedSite]),
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onSubmit: SubmitHandler<accountBlockedSiteViewModel> = async (formData: accountBlockedSiteViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    formData.kiboshDeviceReference = kiboshDeviceReference!;
    formData.deviceId = deviceReference!;
    formData.matchType = "exact";

    const data = convertToSnakecase(formData, [], ["kiboshDeviceReference", "deviceId"]);

    if (!accountBlockedSite?.id)
      response = await apiCall(DashApi.insertClientDeviceFilter(kiboshDeviceReference!, deviceReference, data, abortController.signal));
    else
      response = await apiCall(DashApi.updateClientDeviceFilter(kiboshDeviceReference!, accountBlockedSite?.id!,  data, abortController.signal));

    if (response.success) {
      message.success(`Blocked Site ${!accountBlockedSite?.id ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(AccountBlockedSite.toClass(response.data?.value));

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const onMatchKeyDown = (e: any) => {
    if (e.key === ' ') {
      e.preventDefault(); // Prevents typing space
    }
  }

  return (
    <Popup
      title={`Account: ${account.accountName} - ${
        !accountBlockedSite.id
          ? "New Blocked Site"
          : `Blocked Site Name: ${accountBlockedSite.blockedSiteName}`
      }`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
      fixSize="small"
    >
      {loading && <Loader />}
      <group data-space="15" data-gap="10" data-direction="column">
        <Input
          control={control}
          name="match"
          label="Blocked Site Domain"
          dataLength="auto"
          size="large"
          onKeyDown={onMatchKeyDown}
        />
      </group>
    </Popup>
  );
}
