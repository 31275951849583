// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";

// project modules
import Input from "../../shared/inputs/input";
import Loader from "../../shared/loader";
import Popup from '../../shared/popup/popup';
import yup from "../../../plugins/yup";
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import { ApiResponse } from "../../../models/response";
import { AdminFilterSet, AdminFilterSetElement } from '../../../models/adminFilterSet';
import { adminFilterSetElementViewModel } from '../../../models/types/adminFilterSet';

type Props = {
  closeOnSave?: boolean;
  open: boolean;
  adminFilterSet: AdminFilterSet;
  adminFilterSetElement: AdminFilterSetElement;
  onClose?: () => void;
  onSave?: (accountBlockedSite: any) => void;
};

export default function AccountBlockedSiteModal({ closeOnSave = false, open, adminFilterSet, adminFilterSetElement, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    match: yup.string().label("Match").required()
    .test("no-http", "The domain shouldn't have any http or https", function (val) {
      const valLow = val.toLocaleLowerCase();
      if (valLow.includes("http://") || valLow.includes("https://")) {
        return false;
      }

      return true;
    })
    .test("no-sub-route", "The domain shouldn't have any sub-route", function (val) {
      if (val.includes("/")) {
        return false;
      }

      return true;
    })
    .test("no-valid-url", "Please use a valid domain", function (val) {
      // Regular expression to match domains without http or https and without internal paths
      const domainPattern: RegExp = /^(?:[a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:$|\/$|\/[^/]*)?$/;

      // Check if the domain matches the pattern
      return domainPattern.test(val);
    }),
  });
  const { control, handleSubmit } = useForm<adminFilterSetElementViewModel | any>({
    defaultValues: useMemo(() => adminFilterSetElement, [adminFilterSetElement]),
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    if (open) {

    }
  }, [open]);

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onSubmit: SubmitHandler<adminFilterSetElementViewModel> = async (formData: adminFilterSetElementViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    if (!adminFilterSetElement?.id)
      response = await apiCall(DashApi.insertAdminFilterSetElement(adminFilterSet.id!, formData.match, abortController.signal));
    else
      response = await apiCall(DashApi.updateAdminFilterSetElement(adminFilterSet.id!, adminFilterSetElement.id!, formData.match, abortController.signal));

    if (response.success) {
      message.success(`Filter set element ${!adminFilterSetElement?.id ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(AdminFilterSetElement.toClass(response.data?.value));

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const onMatchKeyDown = (e: any) => {
    if (e.key === ' ') {
      e.preventDefault(); // Prevents typing space
    }
  }

  return (
    <Popup
      title={`Filter Set: ${adminFilterSet.name} - ${
        !adminFilterSetElement.id
          ? "New Filter Set Element"
          : "Update Filter Set Element"
      }`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
    >
      {loading && <Loader />}
      <group data-space="15" data-gap="10" data-direction="column">
        <Input
          control={control}
          name="match"
          label="URL"
          dataLength="360"
          size="large"
          onKeyDown={onMatchKeyDown}
        />
      </group>
    </Popup>
  );
}
